<template>
  <div class="auth-wrapper auth-v1 flex-column">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="90px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>


          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>

          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t("Welcome to fRiDe") }} 👋🏻
          </p>
          <p class="mb-2">
            {{ $t("Please connect to your account") }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              :label="$t('Email')"
              :placeholder="$t('Email')"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('Password')"
              :error-messages="errorMessages.password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                :label="$t('Remember me')"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <router-link
                :to="{name:'auth-forgot-password'}"
                class="mt-1"
              >
                {{ $t("Forgot password?") }}
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="isLoading"
            >
              {{ $t("Login") }}
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <p class="mb-0 me-2">
            {{ $t("Don't have an account ?") }}
          </p>
          <router-link :to="{name:'auth-register'}">
            {{ $t("Signup instead") }}
          </router-link>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import axios from '@axios'
import store from '@/store'
import ability from '@/plugins/acl/ability'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    // Template Ref
    const loginForm = ref(null)

    const { router } = useRouter()
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const isPasswordVisible = ref(false)
    const isLoading = ref(false)
    const email = ref('')
    const password = ref('')
    const errorMessages = ref({ })
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')

    // Reset ability
    vm.$ability.update([])
    axios.get('/logout').then(() => {})
    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      isLoading.value = true
      axios({
        method: 'post',
        url: '/connect',
        data: { email: email.value, password: password.value },
        // eslint-disable-next-line no-unused-vars
        validateStatus: status => true,
      }).catch(error => {
        // this.loading = false
        isLoading.value = false
        console.log(error)
        // eslint-disable-next-line consistent-return
      }).then(response => {
        if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
          if (response.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...response.data.errors }
          } else {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = response.data.message

            // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
          }

          return false
        }
        if (response.data && response.data.id) {
          /* this.$store.set('user/login', true)
          this.$store.set('user/user', response.data)
          this.$router.push('/') */
          isLoading.value = false
          // store.dispatch('app/setUser', response.data)

          const user = response.data

          var userAbility = user.permissions.map(item => {
            const actionResource = item.split('-')
            if (actionResource.length > 0) {
              const action = actionResource[0]
              const subject = actionResource[1]

              return { action, subject }
            }

            return { action: item, subject: item }
          })
          userAbility.push({ action: 'read', subject: 'Public' })

          //userAbility.push({ action: 'all', subject: 'Manage' })

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.permissions
          store.state.app.user = user

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(user))
          router.push('/')



          /* // const { user } = response.data
          const user = {
            ...response.data,
            role: 'admin',
            avatar: require('@/assets/images/avatars/1.png'),
            fullName: response.data.name,
            permissions: [/!* 'edit-article', 'view-role', *!/'read-ACL', 'read-Public', 'manage-all'],
          }

          const userAbility = user.permissions.map(item => {
            const actionResource = item.split('-')
            if (actionResource.length > 0) {
              const action = actionResource[0]
              const subject = actionResource[1]

              return { action, subject }
            }

            return { action: item, subject: item }
          })

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.permissions

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(user)) */
        }
      }).then(() => {
        isLoading.value = false
      })
        .catch(error => {
        // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error)

          /* errorMessages.value = error.response.data.error */
        })
    }

    return {
      handleFormSubmit,
      isPasswordVisible,
      isSnackbarBottomVisible,
      snackBarMessage,
      isLoading,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
